import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './assets/logo/sqwish-white-thick.png'; // Import the logo

const Header: React.FC = () => {
  const location = useLocation();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname === '/') {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      {location.pathname !== '/access' && (
        <header className="fixed top-0 w-full left-0 p-4 flex items-center justify-center z-50">
          <nav className="py-2 px-4 flex justify-between items-center rounded-full w-full max-w-[725px] bg-white/50 backdrop-blur-lg border border-white/20">
            <Link to="/" className="pl-2 pb-0.5" onClick={handleLogoClick}>
              <img src={logo} alt="Sqwish" width="95" />
            </Link>
            <div className="flex items-center justify-center gap-2">
              {location.pathname === '/' ? (
                <>
                  <Link
                    to='/playground'
                    className="hidden sm:flex bg-white text-black py-2 px-4 s-body rounded-[62.5rem] hover:bg-gray-100 hover:shadow transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center border border-gray-100"
                  >
                    Playground
                  </Link>
                  <Link
                    to="/access"
                    target="_blank" // Open in new tab
                    rel="noopener noreferrer" // Security measure
                    className="bg-black text-white py-2 px-4 s-body rounded-[62.5rem] hover:bg-black/70  hover:shadow-md transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center"
                  >
                    Get access
                  </Link>
                </>
              ) : (
                <Link
                  to='/'
                  className="bg-black text-white py-2 px-4 s-body rounded-[62.5rem] hover:bg-black/70  hover:shadow-md transition-colors duration-300 px-4 py-1 h-10 flex items-center justify-center"
                >
                  Home
                </Link>
              )}
            </div>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;