import React from 'react';

import promptCompressionVideoMov from '../../assets/prompt-compression/Sqwish-Prompt-Compression.mov';
import promptCompressionVideoWebM from '../../assets/prompt-compression/Sqwish-Prompt-Compression.webm';
import octopusExplorerFlipped from '../../assets/octopus/octopus-explorer-flipped.png';

const PromptCompressionSection: React.FC = () => {

  return (
    <section className="section bg-green pt-8 lg:pt-16 px-4 pb-24 lg:pb-40 gap-8 lg:gap-16 lg:px-16 -mt-8 lg:-mt-16 items-center rounded-t-3xl lg:rounded-t-[2.5rem] relative ">
      <div className="flex flex-col lg:flex-row items-stretch justify-center max-w-[1580px] mx-auto h-full">
        <div className="w-full lg:w-1/2 xlg:w-2/3 my-2 lg:mb-0 flex items-center justify-center md:px-16 lg:px-2">
          <div className="w-full h-full max-w-[600px] max-h-[600px] lg:max-w-[700px] lg:max-h-[700px] flex items-center justify-center">
            <video className="w-full h-auto max-w-full max-h-full object-contain" autoPlay loop muted playsInline>
              <source
                src={promptCompressionVideoMov}
                type='video/mp4; codecs="hvc1"' />
              <source
                src={promptCompressionVideoWebM}
                type="video/webm" />
            </video>
            {/* <img
              src={promptCompressionVideo}
              className="w-full h-auto max-w-full max-h-full object-contain"
            /> */}
          </div>
        </div>
        <div className="w-full lg:w-1/2 xlg:w-1/3 lg:mb-8 mt-8 lg:mt-0 flex">
          <div className="w-full h-full flex items-center justify-center">
            <div className="max-w-3xl w-full mx-auto flex-col items-center text-center lg:pb-8">
              <div className="w-full max-w-[50rem] mx-auto flex flex-col items-center text-center gap-6 pt-16">
                <div className="relative flex flex-col items-center justify-center ">
                  <div className="absolute flex items-center justify-center w-full top-0 -translate-y-full">
                    <img
                      src={octopusExplorerFlipped}
                      alt="octopus-exploring"
                      className="w-auto h-[57px] pb-2"
                    />
                  </div>
                  <h3 className="text-black s-body">HOW SQWISH WORKS</h3>
                </div>
                <h2 className="text-black heading-2 w-full max-w-[38rem]">Your prompt, compressed in a blink </h2>
                <p className="text-black l-body w-full max-w-[32rem]">
                  Sqwish is a tiny compression model that acts as a lightning-fast middleman between you and AI model providers.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full max-w-[1100px] mx-auto flex flex-col items-center text-center gap-6">
        <PromptCompressionFeatures />
      </div> */}
    </section>
  );
};

export default PromptCompressionSection;