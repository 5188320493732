import React, { useState, useEffect } from 'react';
import { llm_inference, sqwish } from './api';
import llamaImg from './images/llama-v.png';
import sqwishImg from './images/sqwish-llama-v.png';
import { CSSTransition } from 'react-transition-group';

interface TextProps {
    text: string | null;
}

const parseTextToBold = (text: string | null) => {
    if (!text) return null;
    const parts = text.split(/(\*\*[^*]+\*\*)/g);
    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;
        }
        return part;
    });
};

const CustomizedParagraph: React.FC<TextProps> = ({ text }) => {
    return (
        <p className="custom-paragraph whitespace-pre-wrap">
            {parseTextToBold(text)}
        </p>
    );
};

const Playground: React.FC = () => {
    const [inputText, setInputText] = useState('');
    const [submitText, setSubmitText] = useState<string | null>(null);
    const [llmResponse, setLlmResponse] = useState<string | null>(null);
    const [sqwishResponse, setSqwishResponse] = useState<string | null>(null);
    const [finalResponse, setFinalResponse] = useState<string | null>(null);
    const showCompression = !!(submitText && finalResponse && sqwishResponse && llmResponse && (submitText.length > sqwishResponse.length));
    const [isSmallScreen, setIsSmallScreen] = useState(false);


    const [loading, setLoading] = useState({
        llm: false,
        sqwish: false,
        final: false
    });

    const llm_response = async (input_text: string) => {
        const llmResult = await llm_inference(input_text);
        setLlmResponse(llmResult);
        setLoading((prev) => ({ ...prev, llm: false }));
    };

    const sqwish_response = async (input_text: string) => {
        const sqwishResult = await sqwish(input_text);
        setSqwishResponse(sqwishResult);
        setLoading((prev) => ({ ...prev, sqwish: false, final: true }));

        const finalResult = await llm_inference(sqwishResult);
        setFinalResponse(finalResult);
        setLoading((prev) => ({ ...prev, final: false }));
    };

    const handleSubmit = async () => {
        setSubmitText(inputText);
        setLlmResponse(null);
        setSqwishResponse(null);
        setFinalResponse(null);
        setLoading((prev) => ({ ...prev, llm: true, sqwish: true }));
        await Promise.all([llm_response(inputText), sqwish_response(inputText)]);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents the default new line behavior
            handleSubmit();
        }
    };

    useEffect(() => {
        const checkScreenWidth = () => {
            setIsSmallScreen(window.innerWidth < 530); // Adjust the width threshold as needed
        };

        checkScreenWidth();
        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full flex items-center justify-center pt-16 lg:pt-8">
                <div className="flex flex-col lg:flex-row gap-2 relative w-full items-center justify-center px-4 pt-8">
                    <div className="p-4 bg-pink shadow w-full max-w-[600px] h-[660px] rounded-[20px] relative"> {/* bg-[#eabca8] */}
                        <img src={llamaImg} alt="Logo Llama" className={`absolute top-1/2  transform -translate-y-1/2 ${isSmallScreen ? 'left-10 h-[200px]' : 'left-20  h-[300px]'}`} />
                        <div className="absolute right-4 h-full w-[65%]">
                            <div className="flex flex-col gap-6 ">
                                <div className="p-4 bg-white shadow max-w-96 w-full min-w-48 h-48 relative rounded-[20px]">
                                    <h2 className="font-bold text-center mb-3">Original Input</h2>
                                    <textarea
                                        className="w-full p-2 border rounded h-32"
                                        placeholder="Enter text here"
                                        value={inputText}
                                        onChange={(e) => setInputText(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button
                                        className="absolute bottom-2 right-2 px-3 py-1 bg-dark-pink text-white  rounded-[20px] cursor-pointer"
                                        onClick={handleSubmit}
                                    >
                                        ➔
                                    </button>
                                </div>
                                <div className="p-4 bg-white shadow max-w-96 w-full min-w-48 h-[380px] rounded-[20px] relative">
                                    <h2 className="font-bold text-center mb-3 italic">Response to Original</h2>
                                    <div className="h-[310px] w-full overflow-y-auto">
                                        {loading.llm ? <div className="h-full flex items-center justify-center"><div className="loader"></div></div> : <CustomizedParagraph text={llmResponse} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 bg-green shadow w-full max-w-[600px] h-[660px] rounded-[20px] relative"> {/* bg-[#ccd5ae] */}
                        <img src={sqwishImg} alt="Logo Sqwish" className={`absolute top-1/2 transform -translate-y-1/2 lg:left-auto lg:right-8 rotate-180 lg:rotate-0 ${isSmallScreen ? 'left-4 h-[200px]' : 'left-8  h-[300px]'}`} />
                        {/* {
                            loading.sqwish && (
                                <div className="absolute bg-yellow top-20 border-[30px] p-2 h-[30px] min-w-[130px] transform -translate-y-1/2 left-8 lg:left-auto lg:right-8">
                                    Speed
                                </div>
                            )
                        } */}

                        <div className="absolute right-4 h-full w-[65%] lg:right-auto lg:left-4">
                            <div className="flex flex-col gap-6">
                                <div className="p-4 bg-white shadow max-w-96 w-full min-w-48 h-48 relative rounded-[20px]">
                                    <h2 className="font-bold text-center mb-3 italic">Sqwished Input</h2>
                                    <div className="h-32 w-full overflow-y-auto">
                                        {loading.sqwish ? <div className="h-full flex items-center justify-center"><div className="loader"></div></div> : <CustomizedParagraph text={sqwishResponse} />}
                                    </div>
                                </div>

                                <div className="p-4 bg-white shadow max-w-96 w-full min-w-48 h-[380px] rounded-[20px] relative">
                                    <h2 className="font-bold text-center mb-3 italic">Response to Sqwished</h2>
                                    <div className="h-[310px] w-full overflow-y-auto">
                                        {loading.final ? <div className="h-full flex items-center justify-center"><div className="loader"></div></div> : <CustomizedParagraph text={finalResponse} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2'>
                        <CSSTransition
                            in={showCompression}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div className="w-56 h-16 rounded-[20px] bg-white border border-black flex">
                                <div className="w-32 flex items-center justify-center text-4xl font-bold ml-3">
                                    {(submitText && sqwishResponse && submitText) ? `${((100 * (submitText.length - sqwishResponse!.length)) / submitText!.length).toFixed(0)}%` : null}
                                </div>
                                <div className="w-28 flex flex-col justify-center items-center text-center text-xs">
                                    <span>input tokens</span>
                                    <span>removed</span>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playground;
