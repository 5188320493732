import React, { useState, useEffect, useRef } from 'react';
import octopusGif from '../../assets/octopus/videos/octopus.gif';
import overlay from '../../assets/backgrounds/noise_overlay-7.png';
import { Link } from 'react-router-dom';
// import promptCompressionVideo from '../../assets/prompt-compression/low-Sqwish-Prompt-Compression.webm';


const HeroSection: React.FC = () => {
  const [isOctopusLoaded, setIsOctopusLoaded] = useState(true);
  const [isTallWindow, setIsTallWindow] = useState(false);
  const octopusImgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const img = octopusImgRef.current;
    if (img) {
      const handleLoad = () => {
        setIsOctopusLoaded(true);
      };
      img.addEventListener('load', handleLoad);
      img.src = `${octopusGif}?${new Date().getTime()}`; // Add cache-busting query parameter

      return () => {
        img.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerHeight > 900) {
        setIsTallWindow(true);
      } else {
        setIsTallWindow(false);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <section className="section flex flex-col relative w-full h-screen max-h-[1024px] min-h-[720px] overflow-hidden bg-grey pb-16 lg:pb-20">
      <div className="absolute inset-0 overflow-hidden">
        <img src={overlay} alt="background" className="w-auto h-full object-cover object-center min-w-full" />
      </div>

      <div className="flex flex-col justify-end flex-1 relative px-4 lg:px-16 pb-4">
        <div className={`relative w-full ${isTallWindow ? 'bottom-14' : 'bottom-0'}`}>
          <div className="flex flex-col items-center justify-center gap-6 text-center w-full text-black mb-6">
            <h1 className="heading-1 max-w-2xl relative">Boost inference with prompt compression</h1>
            <p className="m-body max-w-[40rem] relative">
              Slash input tokens by up to 75% to cut costs, reduce inference latency and improve performance with our lightning-fast prompt compression API. Compatible with most AI providers.
            </p>
          </div>
          {isOctopusLoaded && (
            <div className="absolute inset-0 overflow-visible pointer-events-none">
              <div className="absolute -top-12 xs:-top-20 sm:-top-28 md:-top-36 lg:-top-40 xl:-top-60">
                {/* border-4 border-black sm:border-green-500 md:border-yellow-500 lg:border-red-500 xl:border-blue-500 */}
                <img
                  ref={octopusImgRef}
                  alt="Octopus animation"
                  className="w-auto object-cover object-center pb-8"
                  id="octopusGif"
                />
              </div>
            </div>
          )}
          <div className="flex flex-col sm:flex-row items-center justify-center lg:gap-8 gap-4 m-body pt-4 md:pt-8 pb-2 relative">
            <Link
              to="/access"
              target="_blank" // Open in new tab
              rel="noopener noreferrer" // Security measure
              className="shadow-[inset_0_2px_4px_rgba(255,255,255,0.1),0_4px_8px_rgba(0,0,0,0.5)] m-body px-1 py-0.5 sm:px-2 sm:py-1 rounded-full bg-black hover:bg-black/80 transition-colors duration-300 text-white h-[50px] w-full max-w-[300px] flex items-center justify-center"
            >
              Get access
            </Link>
            <Link
              to="/playground"
              className="shadow-[inset_0_2px_4px_rgba(255,255,255,0.1),0_4px_8px_rgba(0,0,0,0.5)] m-body px-1 py-0.5 sm:px-2 sm:py-1 rounded-full bg-white hover:bg-gray-200 transition-colors duration-300 text-black h-[50px] w-full max-w-[300px] flex items-center justify-center"
            >
              Try in playground
            </Link>

          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;