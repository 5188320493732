// frontend/src/api.ts

export const llm_inference = async (input_text: string): Promise<string> => {
  const response = await fetch('/api/v1/llm_inference', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ input_text })
  });
  
  if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();
  return data.response;
};

export const sqwish = async (input_text: string): Promise<string> => {
  const response = await fetch('/api/v1/sqwish', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ input_text })
  });
  
  if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();
  return data.response;
};
